import { createSlice } from "@reduxjs/toolkit";

// action/reducer/dispatch('forward')

const authSlice = createSlice({
  name: "@@auth",
  initialState: false,
  reducers: {
    setAuth: (_, action) => action.payload, // true
  },
});

export const { setAuth } = authSlice.actions;
export const authReducer = authSlice.reducer;
