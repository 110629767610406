import { createSlice } from "@reduxjs/toolkit";
import { updatePriceCart } from "../../http/priceApi";

// action/reducer/dispatch('forward')
const initialState = {
  status: "idle",
  items: [], //{name, price, quantity}
  error: null,
};

const cartSlice = createSlice({
  name: "@@cart",
  initialState,

  reducers: {
    addItemToCart: (state, action) => {
      const item = state.items.find(
        (item) => item.id === action.payload.id
        //  && item.price === action.payload.price
      );
      if (item) {
        item.quantity = item.quantity + 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
    },

    removeItemFromCart: (state, action) => {
      const item = state.items.find(
        (item) => item.id === action.payload.id
        //  &&
        // item.price === action.payload.totalPrice.sumPrice
      );
      console.log(action);
      // const item = state.items.find((item) => item.id === action.payload.id);

      if (item && item.quantity > 0) {
        item.quantity = item.quantity - 1;
      }
    },
    deleteItemFromCart: (state, action) => {
      state.items = state.items.filter(
        (item) =>
          !(
            item.id === action.payload.id && item.price === action.payload.price
          )
      );
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePriceCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePriceCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updatePriceCart.fulfilled, (state, action) => {
        state.status = "succeeded";

        // Обновление значения price в items по id для каждого элемента в массиве
        action.payload.prices.forEach((updatedItem) => {
          const index = state.items.findIndex(
            (item) => item.id === updatedItem.deviceId
          );

          if (index !== -1) {
            state.items[index].price = updatedItem.price;
          }
        });
      });
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  deleteItemFromCart,
  clearCart,
} = cartSlice.actions;
export const selectQntByItem = (state, id, price) => {
  const item = state.cart.items.find(
    (item) => item.id === id
    // && item.price === price
  );
  return item ? item.quantity : 0;
};
export const selectAllItems = (state) => {
  // state.cart.items = [];
  return state.cart.items;
};
export const selectTotalPrice = (state) => {
  return state.cart.items.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.price * currentValue.quantity,
    0
  );
};
export const selectTotalQnt = (state) => {
  return state.cart.items.reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity,
    0
  );
};

export const cartReducer = cartSlice.reducer;
