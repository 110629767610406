import { createSlice } from "@reduxjs/toolkit";
import { loadPdf } from "../../../http/zipApi";

const PdfListSlice = createSlice({
  name: "pdfList",
  // initialState,
  initialState: {
    showOption: false,
    list: {},
    uploadedImage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadPdf.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadPdf.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(loadPdf.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      });
  },
});
export default PdfListSlice.reducer;
