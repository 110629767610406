import { createSlice } from "@reduxjs/toolkit";
import { loadMarkups, saveMarkups } from "../../../http/markUpsApi";

const initialState = {
  status: "idle",
  error: null,
  list: [],
};

const markupsSlice = createSlice({
  name: "markups",
  initialState,
  reducers: {
    setErroNull: (state, action) => {
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadMarkups.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadMarkups.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(loadMarkups.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(saveMarkups.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setErroNull } = markupsSlice.actions;
export const markupsReducer = markupsSlice.reducer;
