import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const loadImageByOption = createAsyncThunk(
  "@@image-configure/load-image-by-option",
  async (selectedOptions, { rejectWithValue }) => {
    try {
      // console.log(selectedOptions, "проверка на NULLLLLLLLLLLLLLLLLLL");
      const imageOption = JSON.stringify(selectedOptions);
      // Отправить запрос с параметром в виде строки
      const response = await $host.get(`api/imageConfigure/${imageOption}`);
      console.log(response.data, "КАРТИНКА!!!!!!!!!!!!!!!!");
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
