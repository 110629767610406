import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const addAndUpdateDescribes = createAsyncThunk(
  "@@deviceInfo/create-price",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/deviceInfo/", formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const descriptionFromProducts = createAsyncThunk(
  "@@deviceInfo/create-description",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/deviceInfo/desc", formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const removeDuplicateDevices = createAsyncThunk(
  "@@deviceInfo/deleteDuplicate",
  () => $host.get("api/deviceInfo/duplicate/removeDuplicateDevices")
);

export const loadDeviceInfo = createAsyncThunk(
  "@@deviceInfo/load-device-info",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/deviceInfo/${id}`);
      // console.log(
      //   response.data.dataOptions,
      //   "test RESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS"
      // );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
