import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const createType = createAsyncThunk(
  // '@@types/create-type',
  // (type) => $host.post('api/type', type)
  "@@types/create-type",
  async (type, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/type", type);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const saveTypeDesc = createAsyncThunk(
  // '@@types/create-type',
  // (type) => $host.post('api/type', type)
  "@@types/save-type-desc",
  async ({ id, categoryDescription }, { rejectWithValue }) => {
    try {
      const response = await $host.patch(`api/type/desc/${id}`, {
        categoryDescription,
      });
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const addImageType = createAsyncThunk(
  "@@types/create-image",
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      const response = await $host.post(`api/type/${id}/image`, formData);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addSubCategory = createAsyncThunk(
  "category/addSubCategory",
  async ({ name, parentId }, thunkAPI) => {
    try {
      const response = await $host.post(`api/type/${parentId}`, {
        name: name.toLowerCase(),
        parentId: parentId,
      });
      // Возможно, здесь нужно обработать и вернуть данные из response в нужном формате
      return response;
    } catch (error) {
      // Обработка ошибки'
      console.log(error, "ОШИБКА!!!!!!!!!!!!!!!!!!!!");
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
export const moveDiveceToCat = createAsyncThunk(
  "category/moveDevice",
  async ({ cat, selectedDevices }, thunkAPI) => {
    try {
      const response = await $host.post(`api/type/move/${cat}`, {
        selectedDevices,
      });
      // Возможно, здесь нужно обработать и вернуть данные из response в нужном формате
      return response;
    } catch (error) {
      // Обработка ошибки'
      console.log(error, "ОШИБКА!!!!!!!!!!!!!!!!!!!!");
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const loadTypes = createAsyncThunk("@@types/load-types", () =>
  $host.get("api/type")
);
export const loadbyNavbar = createAsyncThunk("@@types/load-navbar", () =>
  $host.get("api/type/navbar")
);

export const delType = createAsyncThunk("@@types/delete-types", async (id) => {
  const res = await $host.delete(`api/type/${id}`);
  return res.data;
});
export const upType = createAsyncThunk("@@types/upType", async (id) => {
  const res = await $host.patch(`api/type/up/${id}`);
  return res.data;
});
export const downType = createAsyncThunk("@@types/downType", async (id) => {
  const res = await $host.patch(`api/type/down/${id}`);
  return res.data;
});

export const editType = createAsyncThunk(
  "@@types/edit-types",
  async ({ id, name }) => {
    const res = await $host.patch(`api/type/${id}`, { name });
    return res.data;
  }
);

// () => {
//     const { data } = await $host.get('api/type')
//     return data
// }
