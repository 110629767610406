import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const addOptionList = createAsyncThunk(
  "@@options/create-option-list",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/optionsList", formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const addOptionListVariable = createAsyncThunk(
  "@@options/create-option-list",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/optionsList", formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const delOptionListVariable = createAsyncThunk(
  "@@options/delete-option-list",
  async (id) => {
    const response = await $host.delete(`api/optionsList/${id}`);
    return response.data;
  }
);
export const loadOptionList = createAsyncThunk(
  "@@option/load-options-list",
  () => $host.get("api/optionsList")
);
