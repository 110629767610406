import { createSlice } from '@reduxjs/toolkit';

// action/reducer/dispatch('forward') 

const userSlice = createSlice({
    name: '@@user',
    initialState: {},
    reducers: {
        setUser: (_, action) => action.payload, // true
    }
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;