import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "./Container.module.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Container = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname } = useLocation();
  const showAR = useSelector((state) => state.ar?.loading);

  const shouldDisableScroll = ["/admin"];
  const noScrollRoutes = shouldDisableScroll.some((route) =>
    pathname.startsWith(route)
  );

  useEffect(() => {
    if (noScrollRoutes) {
      return; // если текущий путь в noScrollRoutes, не добавляем обработчик прокрутки
    }
    const handleScroll = () => {
      const body = document.body;
      const scrollTop = document.documentElement.scrollTop || body.scrollTop;

      if (scrollTop > 100) {
        setIsVisible(true);
        // body.classList.add("scrolled");
      } else {
        setIsVisible(false);
        // body.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (noScrollRoutes) {
      return; // если текущий путь в noScrollRoutes, не добавляем обработчик прокрутки
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  //   useLayoutEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, [pathname]);

  return (
    // <div className={styles.container}>
    <div className={`${styles.container} ${showAR ? styles.noBackground : ""}`}>
      {children}
      {!showAR && (
        <button
          className={`${isVisible ? styles.scrollbtn : ""}`}
          onClick={scrollToTop}>
          Наверх
        </button>
      )}
    </div>
  );
};

export default Container;
