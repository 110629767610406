import { BrowserRouter } from "react-router-dom";
import AppRouter from "./app/router/AppRouter";
// import Container from "./app/ui/container/Container";
import { store, persistor } from "./app/redux/store/store.js";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          {/* <Container> */}
          <AppRouter />
          {/* </Container> */}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
