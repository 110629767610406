import { createSlice } from "@reduxjs/toolkit";
import {
  addOption,
  deleteOption,
  loadFromArticul,
  loadOptions,
  updateOption,
} from "../../../http/optionApi";

// const initialState = {
//   status: "idle",
//   showOptionr: false,
//   list: [],
//   uploadedImage: null,
// };
const OptionSlice = createSlice({
  name: "Option",
  // initialState,
  initialState: {
    showOption: false,
    list: [],
    selectedOption: null,
    uploadedImage: null,
  },
  reducers: {
    toggleOption: (state, action) => {
      state.showOption = action.payload;
    },
    setImage: (state, action) => {
      state.uploadedImage = action.payload;
      // state.list.push(action.payload);
      // state.list = action.payload.data;
    },
    clearImage: (state) => {
      state.uploadedImage = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addOption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOption.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addOption.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list.push(action.payload);
      })
      .addCase(loadOptions.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadOptions.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadOptions.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
      })
      .addCase(loadFromArticul.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadFromArticul.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadFromArticul.fulfilled, (state, action) => {
        state.status = "received";
        state.selectedOption = action.payload;
      })
      //   .addCase(loadimagesId.fulfilled, (state, action) => {
      //     state.status = "received";
      //     state.list = action.payload.data;
      //   })
      //   .addCase(tempImagesClear.fulfilled, (state, action) => {
      //     state.status = "received";
      //     // state.list = [];
      //   })
      .addCase(deleteOption.fulfilled, (state, action) => {
        state.status = "received";
        state.list = state.list.filter((item) => item.id !== action.payload);
      })
      .addCase(updateOption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateOption.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateOption.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Обновляем слайд в списке. Например, найдем индекс слайда и заменим его
        const index = state.list.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      });
  },
});

export const { setImage, clearImage } = OptionSlice.actions;
export const { toggleOption } = OptionSlice.actions;
export const selectAllOption = (state) => state.Optionr.list;
export default OptionSlice.reducer;
