import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const addImageFull = createAsyncThunk(
  // '@@types/create-type',
  // (type) => $host.post('api/type', type)
  "@@images/add-image-full",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/image/add/full", formData);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const addImageMini = createAsyncThunk(
  // '@@types/create-type',
  // (type) => $host.post('api/type', type)
  "@@images/add-image-mini",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/image/add/mini", formData);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const replaceimage = createAsyncThunk(
  "@@images/replace-image",
  async ({ id, image }, { rejectWithValue }) => {
    try {
      const response = await $host.put(`api/image/${id}`, image);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadimages = createAsyncThunk("@@images/load-images", () =>
  $host.get("api/image")
);
export const saveimages = createAsyncThunk(
  "@@images/save-images",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/image/save/${id}`);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
  // $host.get("api/image/save/tempToimage")
);
export const tempImagesClear = createAsyncThunk(
  "@@images/load-images-clear",
  () => $host.put("api/image")
);
export const loadimagesId = createAsyncThunk(
  "@@images/load-images-fromId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/image/${id}`);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteImage = createAsyncThunk(
  "@@images/delete-images",
  async (img) => {
    const res = await $host.delete(`api/image/${img}`);
    return res.data;
  }
);
export const deleteOldImage = createAsyncThunk(
  "@@images/delete-old-images",
  async (img) => {
    const res = await $host.delete(`api/image/old/${img}`);
    return res.data;
  }
);
