import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const loadMarkups = createAsyncThunk(
  "@@cities/load-markups",
  async (cat, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/markups/${cat}`); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const saveMarkups = createAsyncThunk(
  "@@types/create-markups",
  async ({ data, cat }, { rejectWithValue }) => {
    try {
      const response = await $host.post(`api/markups/create/${cat}`, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
