import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const searchProducts = createAsyncThunk(
  "@@search/search-products",
  async ({ search, selectedCity }, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/search/${search}`, {
        params: { city: selectedCity },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
