// import { useSelector } from "react-redux";
// import AdminPanel from "../../pages/admin/AdminPanel";
// import Baskets from "../../pages/products/baskets/Baskets";
// import Berry from "../../pages/products/berry/Berry";
// import Bouquets from "../../pages/products/bouquets/Bouquets";
// import Boxes from "../../pages/products/boxes/Boxes";
// // import Login from "../../widgets/auth-form/Login";
// import Registration from "../../widgets/auth-form/Registration";
import { lazy } from "react";
import {
  ADMIN_ROUTE,
  BASKETS,
  AR,
  BERRY,
  BOUQUET,
  BOXES,
  DEVICE_NCONFIG_ROUTE,
  DEVICE_ROUTE,
  GLAVNAYA,
  ABOUT,
  // LOGIN_ROUTE,
  // REGISTRATION_ROUTE,
  SEARCHWH,
  CONTACTS,
} from "./utils/consts";
const AdminPanel = lazy(() => import("../../pages/admin/AdminPanel"));
const Baskets = lazy(() => import("../../pages/products/baskets/Baskets"));
const Berry = lazy(() => import("../../pages/products/berry/Berry"));
const Bouquets = lazy(() => import("../../pages/products/bouquets/Bouquets"));
const Boxes = lazy(() => import("../../pages/products/boxes/Boxes"));
const Registration = lazy(() => import("../../widgets/auth-form/Registration"));
const DeviceItem = lazy(() => import("../../entities/product/DeviceItem"));
const Glavnaya = lazy(() => import("../../shared/ui/glavnaya/Glavnaya"));
const SliderAdmin = lazy(() =>
  import("../../pages/admin/content/Outlet/SliderAdmin")
);
const SearchWH = lazy(() =>
  import("../../pages/products/searchWidthAndHeight/SearchWH")
);
const DeviceItemNotConfigure = lazy(() =>
  import("../../entities/product/DeviceItemNotConfigure")
);
const About = lazy(() => import("../../pages/footer/about/About"));
const Contacts = lazy(() => import("../../pages/footer/contacts/Contacts"));
const ArFront = lazy(() => import("../../features/AR/ArFront/ArFront"));
// import DeviceItem from "../../entities/product/DeviceItem";
// import Glavnaya from "../../shared/ui/glavnaya/Glavnaya";
// import SliderAdmin from "../../pages/admin/content/Outlet/SliderAdmin";
// import SearchWH from "../../pages/products/searchWidthAndHeight/SearchWH";
// import DeviceItemNotConfigure from "../../entities/product/DeviceItemNotConfigure";
// import About from "../../pages/footer/about/About";
// import Contacts from "../../pages/footer/contacts/Contacts";
// import ArFront from "../../features/AR/ArFront/ArFront";

// разделение прав между пользователем и админом

export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: AdminPanel,
    children: [
      {
        path: "slider",
        Component: SliderAdmin,
      },
    ],
  },
];

export const publicRoutes = [
  {
    path: BOUQUET,
    Component: Bouquets,
  },
  {
    path: SEARCHWH,
    Component: SearchWH,
  },
  {
    path: ABOUT,
    Component: About,
  },
  {
    path: CONTACTS,
    Component: Contacts,
  },
  {
    path: AR,
    Component: ArFront,
  },
  // {
  //   path: REGISTRATION_ROUTE,
  //   Component: Registration,
  // },
  // {
  //   path: LOGIN_ROUTE,
  //   Component: Login,
  // },
  {
    path: DEVICE_ROUTE,
    Component: DeviceItem,
  },
  {
    path: DEVICE_NCONFIG_ROUTE,
    Component: DeviceItemNotConfigure,
  },
];
