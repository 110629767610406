import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const loadZip = createAsyncThunk(
  // '@@types/create-type',
  // (type) => $host.post('api/type', type)
  "@@types/load-zip",
  async (zip, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/zip", zip);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadPdf = createAsyncThunk(
  // '@@types/create-type',
  // (type) => $host.post('api/type', type)
  "@@types/load-pdf",
  async (articul, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/zip/${articul}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
