import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const loadCities = createAsyncThunk(
  "@@cities/load-cities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/cities"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const loadRegions = createAsyncThunk(
  "@@cities/load-regions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/cities/regions"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const loadGroups = createAsyncThunk(
  "@@cities/load-groups",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/cities/groups"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const saveGroups = createAsyncThunk(
  "@@cities/save-groups",
  async (data, { rejectWithValue }) => {
    try {
      const response = await $host.patch("api/cities/groups", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const saveCities = createAsyncThunk(
  "@@cities/save-cities",
  async (data, { rejectWithValue }) => {
    try {
      const response = await $host.patch("api/cities/saveCities", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const newGpoupName = createAsyncThunk(
  "@@cities/new-group",
  async (data, { rejectWithValue }) => {
    try {
      // console.log(name);
      const response = await $host.post("api/cities", {
        name: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const saveGroupsFromRegions = createAsyncThunk(
  "@@cities/save-groups-regions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await $host.patch("api/cities/groups_region", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const initializeGroups = createAsyncThunk(
  "@@cities/initializeGroups",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/cities/initializeGroups"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const initializeDistrict = createAsyncThunk(
  "@@cities/initializeDistrict",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/cities/initializeDistrict"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const initializeRegions = createAsyncThunk(
  "@@cities/initializeRegions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/cities/initializeRegions"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const initializeCities = createAsyncThunk(
  "@@cities/initializeCities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/cities/initializeCities"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
