import { createSlice } from "@reduxjs/toolkit";
import {
  loadCities,
  loadGroups,
  loadRegions,
  saveGroups,
  saveGroupsFromRegions,
} from "../../../http/cityApi";

const initialState = {
  cities: [],
  regions: [],
  groups: [],
  loading: false,
  error: null,
  successMessage: null,
  selectedCity: {
    id: 365,
    url: "moskva",
    name: "Москва",
    tel: "8(800)302-22-64",
    address: "Москва, Лобненская ул., 18",
  },
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    toggleCities: (state, action) => {
      state.showOption = action.payload;
    },
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadCities.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload;
      })
      .addCase(loadCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loadRegions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadRegions.fulfilled, (state, action) => {
        state.loading = false;
        state.regions = action.payload;
      })
      .addCase(loadRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loadGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload;
      })
      .addCase(loadGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(saveGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.successMessage = "Данные успешно сохранены";
      })
      .addCase(saveGroupsFromRegions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.successMessage = "Данные успешно сохранены";
      });
  },
});
export const { setSelectedCity } = citySlice.actions;
export const { toggleCities, clearSuccessMessage } = citySlice.actions;

export default citySlice.reducer;
