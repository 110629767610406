import { createSlice } from "@reduxjs/toolkit";
import {
  addSlide,
  deleteSlide,
  loadSlides,
  updateSlide,
} from "../../../http/slideApi";
import { searchProducts } from "../../../http/searchApi";

// const initialState = {
//   status: "idle",
//   showSlider: false,
//   list: [],
//   uploadedImage: null,
// };
const searchSlice = createSlice({
  name: "search",
  // initialState,
  initialState: {
    showSearch: false,
    list: [],
  },
  reducers: {
    // toggleSlider: (state, action) => {
    //   state.showSlider = action.payload;
    // },
    setShowSearch: (state, action) => {
      state.showSearch = action.payload;
      // state.list.push(action.payload);
      // state.list = action.payload.data;
    },
    // clearImage: (state) => {
    //   state.uploadedImage = null;
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(searchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.showSearch = true;

        state.list = action.payload;
      });
  },
});

export const { setShowSearch } = searchSlice.actions;

export default searchSlice.reducer;
