import { createSlice } from "@reduxjs/toolkit";
import {
  addOptionList,
  delOptionListVariable,
  loadOptionList,
} from "../../../http/optionListApi";

const OptionListSlice = createSlice({
  name: "optionList",
  // initialState,
  initialState: {
    showOption: false,
    list: [],
    uploadedImage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addOptionList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOptionList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addOptionList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list.push(action.payload);
      })
      .addCase(loadOptionList.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadOptionList.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadOptionList.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
      })
      .addCase(delOptionListVariable.fulfilled, (state, action) => {
        // state.status = "received";
        const id = action.payload;
        state.list = state.list.filter((option) => option.id !== id);
      });
  },
});
export default OptionListSlice.reducer;
