import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
// import Bouquets from "../../pages/products/bouquets/Bouquets";
// import Footer from "../../shared/ui/footer/Footer";
// import Header from "../../shared/ui/header/Header";
import { store } from "../redux/store/store";
import { authRoutes, publicRoutes } from "./routes";
import { ADMIN_ROUTE, BOUQUET, DEVICE_ROUTE } from "./utils/consts";
import { selectAllTypes } from "../redux/store/typeStore/typeSlice";
// import AdminPanel from "../../pages/admin/AdminPanel";
import { selectAllDevices } from "../redux/store/deviceStore/deviceSlice";
// import DeviceItem from "../../entities/product/DeviceItem";
// import OrderBy from "../../entities/orderby/OrderBy";
import stl from "./approuter.module.scss";
// import Breadcrumb from "../../shared/ui/breadcrumbs/Breadcrumb";
// import Glavnaya from "../../shared/ui/glavnaya/Glavnaya";
// import GateForm from "../../widgets/cities/gateForm/GateForm";
// import SliderAdmin from "../../pages/admin/content/Outlet/SliderAdmin";
// import OptionsAdmin from "../../pages/admin/content/Outlet/OptionsAdmin";
// import Left from "../../pages/admin/navbar/Left";
// import Content from "../../pages/admin/content/Content";
// import Options from "../../pages/admin/options/Options";
// import Slider from "../../pages/admin/slider/Slider";
// import Cities from "../../pages/admin/cities/Cities";
// import Login from "../../widgets/auth-form/Login";
// import MapComponent from "../../features/MapGood/MapComponent";
// import Describes from "../../pages/admin/content/describes/Describes";
// import RoutesDelivery from "../../pages/delivery/RoutesDelivery/RoutesDelivery";
// import Guarantee from "../../pages/guarantee/Guarantee";
// import Instructions from "../../pages/admin/content/instructions/Instructions";
// import Raschet from "../../pages/other/raschet/Raschet";
// import LowPrice from "../../pages/other/lowPrice/LowPrice";
// import Description from "../../pages/admin/content/instructions/Description";
// import DeliveryOne from "../../pages/delivery/DeliveryOne";
// import Lider from "../../pages/lider/Lider";
// import Zakaz from "../../pages/other/zakaz/Zakaz";
// import Markups from "../../pages/admin/markups/Markups";
// import Photo from "../../pages/admin/photogalery/Photo";
// import Registration from "../../widgets/auth-form/Registration";
import { setSelectedCity } from "../redux/store/cityStore/citySlice";
// import ArFront from "../../features/AR/ArFront/ArFront";
// import WorkToXLSMass from "../../features/workToXls/WorkToXLSMass";
// import MainHeader from "../../shared/ui/header/MainHeader";
// import HeaderMobile from "../../shared/ui/header/HeaderMobile";
import Container from "../ui/container/Container";
// Lazy loaded components
const AdminPanel = React.lazy(() => import("../../pages/admin/AdminPanel"));
const Bouquets = React.lazy(() =>
  import("../../pages/products/bouquets/Bouquets")
);
// const Berry = React.lazy(() => import('../../pages/products/berry/Berry'));
// const Boxes = React.lazy(() => import('../../pages/products/boxes/Boxes'));
// const Registration = React.lazy(() => import('../../widgets/auth-form/Registration'));
// const DeviceItem = React.lazy(() => import('../../entities/product/DeviceItem'));
const Glavnaya = React.lazy(() => import("../../shared/ui/glavnaya/Glavnaya"));
// const SliderAdmin = React.lazy(() => import('../../pages/admin/content/Outlet/SliderAdmin'));
// const SearchWH = React.lazy(() => import('../../pages/products/searchWidthAndHeight/SearchWH'));
// const DeviceItemNotConfigure = React.lazy(() => import('../../entities/product/DeviceItemNotConfigure'));
// const About = React.lazy(() => import('../../pages/footer/about/About'));
// const Contacts = React.lazy(() => import('../../pages/footer/contacts/Contacts'));
// const ArFront = React.lazy(() => import('../../features/AR/ArFront/ArFront'));
const Login = React.lazy(() => import("../../widgets/auth-form/Login"));
const OrderBy = React.lazy(() => import("../../entities/orderby/OrderBy"));
const RoutesDelivery = React.lazy(() =>
  import("../../pages/delivery/RoutesDelivery/RoutesDelivery")
);
const DeliveryOne = React.lazy(() =>
  import("../../pages/delivery/DeliveryOne")
);
const Lider = React.lazy(() => import("../../pages/lider/Lider"));
const Raschet = React.lazy(() => import("../../pages/other/raschet/Raschet"));
const LowPrice = React.lazy(() =>
  import("../../pages/other/lowPrice/LowPrice")
);
const Zakaz = React.lazy(() => import("../../pages/other/zakaz/Zakaz"));
const Guarantee = React.lazy(() => import("../../pages/guarantee/Guarantee"));
// const ARScene = React.lazy(() => import('../../features/AR/ARScene'));
const Content = React.lazy(() => import("../../pages/admin/content/Content"));
const WorkToXLSMass = React.lazy(() =>
  import("../../features/workToXls/WorkToXLSMass")
);
const Slider = React.lazy(() => import("../../pages/admin/slider/Slider"));
const Options = React.lazy(() => import("../../pages/admin/options/Options"));
const Cities = React.lazy(() => import("../../pages/admin/cities/Cities"));
const Describes = React.lazy(() =>
  import("../../pages/admin/content/describes/Describes")
);
const Markups = React.lazy(() => import("../../pages/admin/markups/Markups"));
const Instructions = React.lazy(() =>
  import("../../pages/admin/content/instructions/Instructions")
);
const Description = React.lazy(() =>
  import("../../pages/admin/content/instructions/Description")
);
const Photo = React.lazy(() => import("../../pages/admin/photogalery/Photo"));
const MainHeader = React.lazy(() =>
  import("../../shared/ui/header/MainHeader")
);
const Breadcrumb = React.lazy(() =>
  import("../../shared/ui/breadcrumbs/Breadcrumb")
);
const Footer = React.lazy(() => import("../../shared/ui/footer/Footer"));

const Text = () => {
  return <h1>Что-нибудь</h1>;
};

const AppRouter = () => {
  const isAuth = useSelector((state) => state.isAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const paramsSearch = useLocation();
  console.log(paramsSearch, "что там в объекте???");
  const selectedCity = useSelector((state) => state?.city?.selectedCity);
  const allCities = useSelector((state) => state?.city?.cities);
  console.log(selectedCity, "выбранный город");
  useEffect(() => {
    // Разбираем URL и получаем часть, которая содержит название города
    const parts = pathname.split("/");
    const cityUrl = parts[1];
    console.log(typeof allCities, "по умолчанию город???????????????");
    if (allCities && allCities.length > 0) {
      const city = allCities.find((city) => city.url === cityUrl);
      console.log(city, "это город???????????????");
      if (city) {
        dispatch(
          setSelectedCity({
            id: city.id,
            url: city.url,
            name: city.name,
            tel: city.tel,
            address: city.address,
          })
        );
      } else {
        return;
      }
    }
  }, []);
  useEffect(() => {
    // if (!selectedCity && !search) {
    //   return;
    // }
    if (selectedCity && paramsSearch.search && paramsSearch.search.length > 0) {
      const parts = paramsSearch.pathname.split("/");
      const pathWithoutCity = parts.slice(2).join("/");
      let newPath = `/${selectedCity.url}/${pathWithoutCity}`;

      // Добавляем параметры поиска, если они есть и не пусты

      // newPath += search;
      const url = newPath + paramsSearch.search;

      console.log(url, "новый путь");
      // navigate(newPath);
      navigate(url);
    }
    // else if (pathname === "login") {
    //   navigate(`/login`);
    //   return;
    // }
    else {
      const parts = paramsSearch.pathname.split("/");
      const pathWithoutCity = parts.slice(2).join("/");
      navigate(`/${selectedCity.url}/${pathWithoutCity}`);
    }
    return;
  }, [selectedCity]);

  //   useEffect(() => {
  //     const parts = pathname.split("/");
  //     const result = parts[1];
  //     console.log(result, "первичная загрузка!!!!!!!!!!!!!!!!!!!!! адреса");
  //     if(result !== selectedCity.url){
  // dispatch
  //     }
  //   }, []);
  // const showAR = pathname.includes("/ar");
  const showAR = useSelector((state) => state.ar?.loading);

  return (
    <>
      <Container>
        <div className={stl.wrapper}>
          <Suspense fallback={<div>загрузка...</div>}>
            <MainHeader />
            <Breadcrumb />
          </Suspense>
          <Suspense fallback={<div>загрузка...</div>}>
            <Routes>
              <Route path="/" element={<Glavnaya />} />

              <Route path="checkout" element={<OrderBy />} />
              <Route path="delivey_routes" element={<RoutesDelivery />} />
              <Route path="delivey_one" element={<DeliveryOne />} />
              <Route path="lider" element={<Lider />} />
              <Route path="raschet" element={<Raschet />} />
              <Route path="low_price" element={<LowPrice />} />
              <Route path="zakaz" element={<Zakaz />} />
              <Route path="guarantee" element={<Guarantee />} />
              {isAuth && (
                <Route path="admin" element={<AdminPanel />}>
                  <Route index element={<Content />} />
                  <Route
                    path="updatexls"
                    element={<WorkToXLSMass type={"massupdate"} />}
                  />

                  <Route path="slider" element={<Slider />} />
                  {/* <Route path="maprr" element={<MapComponent />} /> */}
                  <Route path="options" element={<Options />} />
                  <Route path="cities" element={<Cities />} />
                  <Route path="describes" element={<Describes />} />
                  <Route path="markups" element={<Markups />} />
                  <Route path="instructions" element={<Instructions />} />
                  <Route path="description" element={<Description />} />
                  <Route path="photo" element={<Photo />} />
                  <Route path="*" element={<Content />} />
                </Route>
              )}

              <Route path="loginturbokapmekc" element={<Login />} />
              {/* <Route path="registration" element={<Registration />} /> */}
              {publicRoutes.map(({ path, Component }) => (
                <Route
                  key={path}
                  // path={`/${selectedCity.url}/${path}`}
                  path={`/:city/${path}`}
                  element={<Component />}
                />
              ))}
              {!isAuth && <Route path="/admin/" element={<Bouquets />} />}

              {/* {isAuth && <Route path="/admin/:id" element={<AdminPanel />}></Route>} */}
              {/* <Route path="/zamer" element={<GateForm />} /> */}
              <Route path="*" element={<Glavnaya />} />
            </Routes>
          </Suspense>
        </div>
        {/* <Footer /> */}
        {!showAR && (
          <Suspense fallback={<div>загрука...</div>}>
            <Footer />
          </Suspense>
        )}
      </Container>
      {/* {showAR && (
        // <div
        //   style={{
        //     position: "absolute",
        //     top: 0,
        //     left: 0,
        //     right: 0,
        //     bottom: 0,
        //     zIndex: 1001,
        //   }}>
        <ArFront />
        // </div>
      )} */}
    </>
  );
};
export default AppRouter;
