import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const arSlice = createSlice({
  name: "ar",
  initialState,
  reducers: {
    toggleAr: (state, action) => {
      state.loading = action.payload;
    },
  },
});
export const { toggleAr } = arSlice.actions;

export default arSlice.reducer;
