import { createSlice } from "@reduxjs/toolkit";
import { loadImageByOption } from "../../../http/ImageConfigureApi";

const initialState = {
  selectedOptions: {
    color: null,
    model: null,
    additionalOptions: [],
  },
  withoutKalita: false,
  imageUrl: "",
  status: "idle",
  error: null,
};

const ImageConfigureSlice = createSlice({
  name: "ImageConfigure",
  initialState,
  reducers: {
    setColor: (state, action) => {
      state.selectedOptions.color = action.payload;
    },
    setModel: (state, action) => {
      state.selectedOptions.model = action.payload;
    },
    setModelWithoutKalitka: (state, action) => {
      state.withoutKalita = action.payload;
    },
    toggleAdditionalOption: (state, action) => {
      const optionId = action.payload;
      const index = state.selectedOptions.additionalOptions.indexOf(optionId);

      if (index === -1) {
        state.selectedOptions.additionalOptions.push(optionId);
      } else {
        state.selectedOptions.additionalOptions =
          state.selectedOptions.additionalOptions.filter(
            (id) => id !== optionId
          );
      }
    },
    removeKalitka: (state, action) => {
      const optionId = action.payload;
      const index = state.selectedOptions.additionalOptions.indexOf(optionId);

      if (index === -1) {
        return;
      } else {
        state.selectedOptions.additionalOptions =
          state.selectedOptions.additionalOptions.filter(
            (id) => id !== optionId
          );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadImageByOption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadImageByOption.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        // console.log(action.payload, 'КАРТИНКА"""""""""""""');
      })
      .addCase(loadImageByOption.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.imageUrl = action.payload;
      });

    //   .addCase(addSelectedOption, (state, action) => {
    //     // state.selectedOptions.push(action.payload);
    //   })
    //   .addCase(removeSelectedOption, (state, action) => {
    //     // state.selectedOptions = state.selectedOptions.filter(
    //     //   (id) => id !== action.payload
    //     // );
    //   });
  },
});

export const {
  setColor,
  setModel,
  toggleAdditionalOption,
  setModelWithoutKalitka,
  removeKalitka,
} = ImageConfigureSlice.actions;

export default ImageConfigureSlice.reducer;
