export const ADMIN_ROUTE = "admin";
export const LOGIN_ROUTE = "login";
export const REGISTRATION_ROUTE = "registration";
export const CART_ROUTE = "cart";
// export const DEVICE_ROUTE = "product/:id";
export const DEVICE_ROUTE = "products/:id/:url";
export const AR = "ar";
export const DEVICE_NCONFIG_ROUTE = "product/:id/:url";
export const ABOUT = "about";
export const CONTACTS = "contacts";

export const BOUQUET = "category/:name";
// export const SEARCHWH =
//   "searchwh/:width/:height/:originalWidth/:originalHeight";
export const SEARCHWH = "searchwh";
export const BOXES = "boxes";
export const BASKETS = "baskets";
export const BERRY = "berry";
// export const GLAVNAYA = "/";
