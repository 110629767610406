import { createSlice } from "@reduxjs/toolkit";
import {
  addImage,
  addImageFull,
  addImageMini,
  deleteImage,
  deleteOldImage,
  loadimages,
  loadimagesId,
  tempImagesClear,
} from "../../../http/imageApi";

const initialState = {
  status: "idle",
  error: null,
  id: "",
  list: [],
  doplist: [],
  // thumb: [],
};

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    // addImage: (state, action) => {
    //     // state.push(action.payload);
    //     state.list.push(action.payload.img);

    // },
    // deleteImage: (state, action) => {
    //     const index = state.findIndex(img => img.id === action.payload);
    //     if (index !== -1) {
    //         state.splice(index, 1);
    //     }
    // },
    resetImages: (state) => {
      return {
        ...state,
        list: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addImageFull.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addImageFull.fulfilled, (state, action) => {
        state.status = "succeeded";
        const images = action.payload.image;
        state.id = images.id;
        // state.newlist.push(images);
      })
      .addCase(addImageFull.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addImageMini.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addImageMini.fulfilled, (state, action) => {
        state.status = "succeeded";
        const images = action.payload.image;
        // console.log(typeof state.doplist, "какой мать его тип?");
        state.doplist.push(images);
      })
      .addCase(addImageMini.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(loadimages.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadimages.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadimages.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload;
      })
      .addCase(loadimagesId.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data.images;
        state.doplist = action.payload.data.imagesNew;
      })
      .addCase(tempImagesClear.fulfilled, (state, action) => {
        state.status = "received";
        // state.list = [];
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        state.status = "received";
        console.log(action.payload, "что удаляем");
        state.doplist = state.doplist.filter(
          (item) => item.id != action.payload.id
        );
      })
      .addCase(deleteOldImage.fulfilled, (state, action) => {
        state.status = "received";
        console.log(action.payload, "что удаляем");
        state.list = state.list.filter((item) => item.id != action.payload.id);
      });
    // resetImages: (state) => {
    //     return {
    //         ...state,
    //         list: []
    //     };
    // }
  },
});

export const selectAllGallery = (state) => state.galery.list;
export const { resetImages } = gallerySlice.actions;
export const galleryReducer = gallerySlice.reducer;
