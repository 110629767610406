import { createSlice } from "@reduxjs/toolkit";
import {
  addSlide,
  deleteSlide,
  loadSlides,
  updateSlide,
} from "../../../http/slideApi";

// const initialState = {
//   status: "idle",
//   showSlider: false,
//   list: [],
//   uploadedImage: null,
// };
const sliderSlice = createSlice({
  name: "slider",
  // initialState,
  initialState: {
    showSlider: false,
    list: [],
    uploadedImage: null,
  },
  reducers: {
    toggleSlider: (state, action) => {
      state.showSlider = action.payload;
    },
    setImage: (state, action) => {
      state.uploadedImage = action.payload;
      // state.list.push(action.payload);
      // state.list = action.payload.data;
    },
    clearImage: (state) => {
      state.uploadedImage = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addSlide.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addSlide.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addSlide.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list.push(action.payload);
      })
      .addCase(loadSlides.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadSlides.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadSlides.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
      })
      //   .addCase(loadimagesId.fulfilled, (state, action) => {
      //     state.status = "received";
      //     state.list = action.payload.data;
      //   })
      //   .addCase(tempImagesClear.fulfilled, (state, action) => {
      //     state.status = "received";
      //     // state.list = [];
      //   })
      .addCase(deleteSlide.fulfilled, (state, action) => {
        state.status = "received";
        state.list = state.list.filter(
          (item) => item.imageUrl !== action.payload
        );
      })
      .addCase(updateSlide.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSlide.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateSlide.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Обновляем слайд в списке. Например, найдем индекс слайда и заменим его
        const index = state.list.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      });
  },
});

export const { setImage, clearImage } = sliderSlice.actions;
export const { toggleSlider } = sliderSlice.actions;
export const selectAllSlider = (state) => state.slider.list;
export default sliderSlice.reducer;
