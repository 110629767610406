import { createSlice } from "@reduxjs/toolkit";

import {
  descriptionFromProducts,
  loadDeviceInfo,
} from "../../../http/describesApi";

const initialState = {
  status: "idle",
  error: null,
  list: [],
};

const descriptionSlice = createSlice({
  name: "description",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(loadDeviceInfo.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadDeviceInfo.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadDeviceInfo.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload;
      })
      .addCase(descriptionFromProducts.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(descriptionFromProducts.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(descriptionFromProducts.fulfilled, (state, action) => {
        state.status = "received";
        // state.list = action.payload;
      });
  },
});

export const descriptionReducer = descriptionSlice.reducer;
